<template>
  <TransitionGroup
    name="alert"
    class="grToastContainer"
    tag="div"
    :class="[position, align]"
  >
    <div
      v-for="(item, index) in alerts"
      :key="index"
      :class="item.type"
      class="grToast"
    >
      <div class="grMsg">{{ index }} - {{ item.alert }}</div>
      <div class="grClose" @click="removeAlert(index)">&times;</div>
    </div>
    <div v-if="alerts.length>1" class="clearAll" :style="[{'text-align': align}]" @click="removeAllAlerts()">Clear all</div>
  </TransitionGroup>
</template>

<script>
export default {
  name: "ToastAlert",
  props: {
    alerts: {
      type: Array,
      default: () => [],
    },
    align: {
      type: String,
      default: "right",
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
  emits: ["update:alerts"],
  methods: {
    removeAlert(index) {
      let newArray = this.alerts;
      newArray.splice(index, 1);
      this.$emit("update:alerts", newArray);
    },
    removeAllAlerts() {
      this.$emit("update:alerts", []);
    }
  },
};
</script>

<style lang="css" >
.grToastContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  z-index: 999;
  width: 90%;
  max-width: 500px;
}
.grToastContainer.right {
  right: 20px;
}

.grToastContainer.left {
  left: 20px;
}

.grToastContainer.center {
  left: 50%;
  transform: translateX(-50%);
}

.grToastContainer.bottom {
  bottom: 40px;
}
.grToastContainer.center {
  top: 50%;
  transform: translateY(-50%);
}
.grToastContainer.top {
  top: 40px;
}
.grToast {
  padding: 5px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-left: 4px solid transparent;
  background: white;
}

.grMsg {
  padding: 10px;
}
.grClose {
  font-weight: bolder;
  font-size: 1.4rem;
  padding: 10px;
  cursor: pointer;
}

/** Alerts color */
.info {
  border-color: var(--gr-alert-info);
}

.danger {
  border-color: var(--gr-alert-danger);
}

.success {
  border-color: var(--gr-alert-success);
}
.light {
  border-color: var(--gr-alert-light);
}

.dark {
  border-color: var(--gr-alert-dark);
}

.warning {
  border-color: var(--gr-alert-warning);
}

.clearAll {
  font-size: 10px;
  cursor: pointer;
}
</style>