<template>
  <Transition name="modal" tag="div" :duration="1000" >
     <div class="grModal" v-if="active">
      <div class="grModalDialog">
        <div class="grModal-header">
          <slot name="header"></slot>
          <div class="closeGrModal" @click="closeModal()">&times;</div>
        </div>
        <div class="grModal-body">
          <slot name="body"></slot>
        </div>
        <div class="grModal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
     </div>
  </Transition>
</template>
<script>
export default {
  name: "ModalDialog",
  props: {
    active: {
      type: Boolean
    }
  },
  emits: ['update:active'],
  data() {
    return {}
  },
  methods: {
    closeModal() {
      this.$emit('update:active', false);
    }
  }
}
</script>

<style lang="css" scoped>

.grModal {
  background: rgba(0,0,0,0.4);
position: fixed;
width: 100%;
height: 100%;
padding: 30px;
top: 0;
left: 0;
display: flex;
flex-direction: column;
justify-content: center;
}
.grModalDialog {
  background: var(--gr-bg-modal);
  /* padding: 10px; */
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: var(--gr-size-modal);
  margin: auto;
  width: 90%;
}
@media screen and (min-width:961px) {
  .grModal-body  {
    max-height: calc(80vh - 200px);
  }
}

.grModal-header {
  background: var(--gr-bg-header-modal);
  color: var(--gr-text-header-modal);
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}
.grModal-body {
  max-height: 70vh;
  overflow: auto;
  padding: 40px 20px;
  color: var(--gr-text-body-modal);
}
.grModal-footer {
  padding:20px;
  background: var(--gr-bg-footer-modal);
  color: var(--gr-text-footer-modal);
}
.closeGrModal {
  font-size: 2rem;
  line-height: 1;
  padding: 5px;
  cursor: pointer;
}

.closeGrModal:hover {
  opacity: .5;
}
  /** Animaciones transicion */
.modal-move, /* apply transition to moving elements */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter-from
{
  opacity: 0;
}
.modal-leave-to 
{
  opacity: 0;

}


.modal-move .grModalDialog, .modal-enter-active .grModalDialog{
  transition: all 1s ease;
}


.modal-enter-from .grModalDialog
{
  opacity: 0;
  transform: translateY(-150px);
}

</style>