<template>
  <TransitionGroup name="alert" tag="div" class="grNotificationAlerts">
    <div
      v-for="(item, index) in alerts"
      :key="index"
      :class="[item.type, align]"
      class="grAlert"
    >
      <div class="grMsg">{{ index }} - {{ item.alert }}</div>
      <div class="grClose" @click="removeAlert(index)">&times;</div>
    </div>
  </TransitionGroup>
</template>

<script>
export default {
  name: "NotificationAlert",
  props: {
    alerts: {
      type: Array,
    },
    align: {
      type: String,
      default: "between",
    },
  },
  emits: ["update:alerts"],
  methods: {
    removeAlert(index) {
      let newArray = this.alerts;
      newArray.splice(index, 1);
      this.$emit("update:alerts", newArray);
    },
  },
};
</script>

<style lang="css" scoped>
.grNotificationAlerts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.grAlert {
  padding: 5px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.grAlert.between {
  justify-content: space-between;
}

.grAlert.center {
  justify-content: center;
}
.grAlert.start {
  justify-content: start;
}

.grAlert.end {
  justify-content: end;
}

.grMsg {
  padding: 10px;
}
.grClose {
  font-weight: bolder;
  font-size: 1.4rem;
  padding: 10px;
  cursor: pointer;
}

.grClose:hover {
  opacity: 0.5;
}

/** Alerts color */
.info {
  background: var(--gr-alert-bg-info);
  color: var(--gr-alert-text-info);
}

.danger {
  background: var(--gr-alert-bg-danger);
  color: var(--gr-alert-text-danger);
}

.success {
  background: var(--gr-alert-bg-success);
  color: var(--gr-alert-text-success);
}
.light {
  background: var(--gr-alert-bg-light);
  color: var(--gr-alert-text-light);
}

.dark {
  background: var(--gr-alert-bg-dark);
  color: var(--gr-alert-text-dark);
}

.warning {
  background: var(--gr-alert-bg-warning);
  color: var(--gr-alert-text-warning);
}
</style>