<template>
    <div class="faqContainer">
        <div class="grFaq" v-for="(item, index) in faqs" :key="index" :class="{active: item.open}">
            <div class="grFaq-header" @click="item.open = !item.open">
                <div class="grQuestion">
                  {{item.faq}}
                </div>
                <div class="grToggleFaq">
                    <span class="triangulo-equilatero-bottom"></span>
                </div>
            </div>
            <Transition name="slideToggle">
                <div class="grFaq-body" v-if="item.open" >
                   <div v-html="item.answer"></div>
                </div>
            </Transition>
           
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModuloFaq',
    props: {
        faqs: {
            type: Array,
            required: true
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.grFaq {

   
}

.grFaq.active .grFaq-header {
    /* border-color:inherit; */
    background-color: var(--gr-faq-active);
    transition: 0.3s ease all;
}

.grFaq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1.2rem 1.5rem;
    cursor: pointer;
    border:1px solid var(--gr-faq-active);
}

.grFaq-header:hover {
    background-color: var(--gr-faq-active);
    transition: 0.3s ease all;
}

.grToggleFaq {
   position: relative;
   transition: 0.3s ease all;
}
.grFaq.active .grToggleFaq{
transform: rotate(180deg);
}
.triangulo-equilatero-bottom {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -30%);
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-top: 8px solid #2e2e2e;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    cursor: pointer;
    transition: 0.3s ease all;
}

.grFaq-body {
    text-align: left;
    padding: 1.456rem;
    border:1px solid #cecece;
    /* transition: 0.5s ease all;
    max-height: 0px;
    overflow: hidden; */
}
/* .grFaq.active .grFaq-body {
    max-height: auto;
} */
</style>