<template>
  
        <div v-if="active" class="lds-ring"><div></div><div></div><div></div><div></div></div>

</template>

<script>
export default {
  name: "LoaderBlock",
  props: {
    active: Boolean,
  },
  data() {
    return {
      
    }
  }
};
</script>
<style scoped>

.lds-ring {
  display: inline-block;
  position: relative;
  width: 90%;
  height: 90%;
  aspect-ratio: 1/1;
  max-width: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /* margin: 8px; */
   aspect-ratio: 1/1;
  border: 8px solid var(--gr-primary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--gr-primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>
