<template>
    <div class="coolSelector" :class="{ 'open': open }"
        :style="[{ '--coolSelector-color': color }, { '--coolSelector-opColor': color + '50' }]">
        <div class="inputSelectJs">

            <!-- <span class="placeholder">Placeholder de prueba</span> -->
            <div v-if="multiple && tags" class="tags">
                <span v-for="(item, index) in selectedOptions" :key="index"
                    :style="[{ 'border-color': color }, { 'background': color + '60' }]" @click="removeOption(index)">{{
                        item.label }} <strong>&times;</strong></span>
            </div>
            <input type="text" class="inputSelectJsText" v-model="selectedLabel"
                @click="(multiple) ? open = true : toggleDropdown()" :readonly="!multiple" />

            <div class="inputSelectJsArrow" @click="toggleDropdown()"></div>
        </div>
        <div class="listSelectJs">
            <ul>
                <li v-for="(item, index) in listOptions" :key="index" :data-value="item.value" @click="selectOption(item)">
                    {{
                        item.label }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CoolSelect",
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object
        },
        options: {
            type: Object,
            required: true
        },
        color: {
            type: String,
            default: '#0a66c2'
        },
        tags: {
            type: Boolean,
            default: true
        }
    },
    emits: ['update:value'],
    data() {
        return {
            open: false,
            selectedLabel: '',
            selectedOptions: []
        }
    },
    computed: {
        listOptions() {
            if (this.multiple) {
                return this.options.filter((e) => {
                    return (
                        e.label.toLowerCase().includes(this.selectedLabel.toLowerCase()) &&
                        !this.selectedOptions.some((selected) => selected.label === e.label)
                    );

                });
            } else {
                return this.options;
            }
        }
    },
    methods: {
        toggleDropdown() {
            this.open = !this.open;
        },
        reset() {

            this.selectedOptions = []
            this.selectedLabel = '';

        },
        setValue(newValue) {
            if (this.multiple) {
                this.selectedOptions = newValue;
            } else {
                this.selectedLabel = newValue.label;
            }
        },
        selectOption(option) {
            // Option selection logic...
            if (!this.multiple) {
                this.closeDropdown();
                this.selectedLabel = option.label;
                this.$emit('update:value', option);
            } else {
                this.selectedLabel = '';
                this.selectedOptions.push(option);
                this.$emit('update:value', this.selectedOptions);


            }
        },
        removeOption(index) {
            this.selectedOptions.splice(index, 1);
        },
        closeDropdown() {
            this.open = false;
        },
    },
    mounted() {
        if (this.value) {
            if (this.multiple) {
                this.selectedOptions = this.value;
            } else {
                this.selectedLabel = this.value.label;
            }


        }
    }
}
</script>

<style scoped>
.inputSelectJs {
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputSelectJs span.placeholder {
    opacity: 0.6;
    white-space: nowrap;
    background-color: transparent !important;
}

.inputSelectJsText {
    font-size: inherit;
    width: 100%;

    border: none !important;
    outline: none !important;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
    white-space: nowrap;
}

/* Estilos para la barra de desplazamiento en WebKit (Safari y Chrome) */
.inputSelectJsText::-webkit-scrollbar {
    width: 2px;
    /* Ancho de la barra de desplazamiento */
    height: 5px;
}

.inputSelectJsText::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    /* Color de fondo de la pista */
}

.inputSelectJsText::-webkit-scrollbar-thumb {
    background-color: rgba(0,
            0,
            0,
            0.5);
    /* Color del pulgar (barra de desplazamiento) */
    border-radius: 4px;
    /* Radio de borde del pulgar */
}

/* Estilos para la barra de desplazamiento en Firefox */
.inputSelectJsText {
    scrollbar-color: rgba(0,
            0,
            0,
            0.5);
    /* Color del pulgar (barra de desplazamiento) */
    scrollbar-width: thin;
}

/* Estilos para la barra de desplazamiento en Edge y navegadores basados en Chromium */
.inputSelectJsText::-ms-scrollbar {
    width: 2px;
    /* Ancho de la barra de desplazamiento */
    height: 5px;
}

.inputSelectJsText::-ms-scrollbar-thumb {
    background-color: rgba(0,
            0,
            0,
            0.5);
    /* Color del pulgar (barra de desplazamiento) */
    border-radius: 4px;
    /* Radio de borde del pulgar */
}

.inputSelectJsArrow {
    /* border:1px solid #ccc; */
    padding: 10px;
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    transition: 0.4s ease all;
}

.inputSelectJsArrow::after {
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-width: 5px 5px 0 5px;
    border-color: #000000 transparent transparent transparent;
}

.listSelectJs * {
    transition: 0.4s ease all;
}

.listSelectJs {
    position: relative;
    margin-top: 0px;
}

.listSelectJs ul {
    visibility: hidden;
    display: block;
    list-style: none;
    padding: 0px;
    background-color: white;
    border: 1px solid #ccc;
    position: absolute;
    margin: 0px;
    max-height: 400px;
    overflow: auto;
    width: 100%;
    border-radius: 10px;
    z-index: 0;
    transition: 0.4s ease all;
    opacity: 0;
}

.listSelectJs ul li {
    padding: 10px 15px;
    cursor: pointer;
}

.listSelectJs ul li.active {
    display: none;
}

.listSelectJs ul li:hover {
    background: var(--coolSelector-opColor);
}

div.open .inputSelectJsArrow {
    transform: rotate(180deg);
}

div.open .listSelectJs ul {
    opacity: 1;
    visibility: visible;
    z-index: 9;
}

span.selectJSTag {
    padding: 5px 10px;
    font-size: 0.9em;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
}

span.selectJSTag::after {
    padding: 5px;
    margin-left: 5px;
    content: "\00d7";
    font-weight: 900;
    font-size: 1.5em;
    vertical-align: middle;
}


.tags {
    display: flex;
    gap: 5px;
    padding: 5px;
}

.tags span {
    color: white;
    border: 1px solid;
    padding: 3px 8px;
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;
    border-radius: 20px;
    cursor: pointer;
}

.tags span strong {
    font-size: 1.7em;
    vertical-align: middle;
    margin-left: 5px;
}
</style>