<template>
    <Transition name="offCanvas" tag="div" :duration="1000" >
   <div class="gr-offcanvas" :class="{ 'active': opened}" v-if="opened">       
        <aside class="gr-offcanvas-container">
            <div class="gr-offcanvas-header">
                <span>{{ title }}</span>
                <div @click="closeOffCanvas()" style="cursor: pointer;"><svg xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M18 6l-12 12"></path>
                        <path d="M6 6l12 12"></path>
                    </svg></div>
            </div>
            <div class="gr-offcanvas-body">
                <slot>...</slot>
            </div>
            <div class="gr-offcanvas-footer">
                <slot name="footer"></slot>
            </div>
        </aside>


    </div>
</Transition>
</template>
<script>
export default {
    name: 'OffCanvas',
    props: {
        title: {
            type: String,
            default: ''
        },
        opened: {
            type: Boolean
        }
    },
    emits: ['update:opened'],
    data() {
        return {
            open: false,
        }
    },
    watch: {
        opened(newState) {
                if(newState) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
               
                }
            }
    },
    methods: {
        closeOffCanvas() {
            // this.$emit('close');
            this.$emit('update:opened', false);
        }

    },
    created() {
        this.open = this.opened;
    },

}
</script>

<style scoped>
.gr-offcanvas {

    border: 1px solid;
    position: fixed;
    width:100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    top: 0;
    left: 0px;
    z-index: 9998;
}

.gr-offcanvas-container{
background: white;
 display: flex;
  flex-direction: column;
justify-content: space-between;
position: absolute;
right: 0;
top: 0;
width: 500px;
height: 100vh;
transition: 1s ease all;
transform: translateX(0px);
}
/* .gr-offcanvas.active  .gr-offcanvas-container {
    left: 0px;
    transition: 1s ease all;
} */

.gr-offcanvas-header {
    padding: 20px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.gr-offcanvas-header span {
    font-weight: bold;
}
.gr-offcanvas-body, .gr-offcanvas-footer {
    padding: 20px;
}

.gr-offcanvas-body {
    flex: auto;
}
  /** Animaciones transicion */
  .offCanvas-move, /* apply transition to moving elements */
.offCanvas-enter-active,
.offCanvas-leave-active {
  transition: all 0.5s ease;
}

.offCanvas-enter-from
{
  opacity: 0;
}
.offCanvas-leave-to 
{
  opacity: 0;

}

.offCanvas-enter-active .gr-offcanvas-container{
  transition: all .5s ease;
}


.offCanvas-enter-from .gr-offcanvas-container
{
  /* opacity: 0; */
  transform: translateX(600px);

}
.offCanvas-leave-to  .gr-offcanvas-container
{
    transform: translateX(600px);

}

@media screen and (max-width:768px) {
    .gr-offcanvas-container {
        width: 100%;
    }
}

</style>