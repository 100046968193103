/* eslint-disable */
import '@/assets/styles.css';
import CoolSelect from '@/components/CoolSelect.vue';
import LoaderBlock from '@/components/LoaderBlock.vue';
import LoaderPage from '@/components/LoaderPage.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import ModuloFaq from '@/components/ModuloFaq.vue';
import NotificationAlert from '@/components/NotificationAlert.vue';
import ToastAlert from '@/components/ToastAlert.vue';
import OffCanvas from '@/components/OffCanvas.vue';

// Exportación de los componentes individualmente
export {
  CoolSelect,LoaderBlock,LoaderPage,ModalDialog,ModuloFaq,NotificationAlert,ToastAlert,OffCanvas
}
// Instalación de la librería como un plugin
export function install(Vue) {
    Vue.component('CoolSelect', CoolSelect)
    Vue.component('LoaderBlock', LoaderBlock)
    Vue.component('LoaderPage', LoaderPage)
    Vue.component('ModalDialog', ModalDialog)
    Vue.component('ModuloFaq', ModuloFaq)
    Vue.component('NotificationAlert', NotificationAlert)
    Vue.component('ToastAlert', ToastAlert)
    Vue.component('OffCanvas', OffCanvas)
  }

  if (typeof window !== 'undefined' && window.Vue) {
    Vue.use({ install });
  }
// Exportación de la librería como plugin
  export default { install: install }